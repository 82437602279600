.whole-slide{
   font-family: Georgia, 'Times New Roman', Times, serif;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}


.tv-logo{
   width: 20%;
   margin-left: 40%;
}
.insta-logo{
   width: 150px;
   margin-right: 800px;
}
.ft-logo{
   margin-top: 10px;
   width: 200%;
}
  .video-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    object-fit: cover;  /* Cover the entire background */
    z-index: -1;  /* Ensure the video is behind other content */
  }
  @media (max-width: 767px) {
   .video-bg {
      height: 30%;
      bottom: 50;
   }
}
  
  .slide2{
    background-color: #fff;
    text-align: center;
    margin: 10% 0 0 0;
    font-size: 20px;
  }

  .slide2 h4{  
    padding-top: 40px;
    font-size: 40px;  
  }
  @media (max-width: 767px) {
   .slide2 h4 {
      padding-top: 40px;
    font-size: 25px; 
   }
}

  #silde-btm
  {  
    padding-bottom: 40px;
     }
  .slide3 {
    color: #ffffff;
    text-align: center;
    margin: 120px 120px;
  }
  .slide3 p{
   font-size: 30px;
  }
  #quote{
   font-size: 25px;
  }
  @media (max-width: 767px) {
   .slide3 {
      margin: 40px 40px;
    }
   .slide3 p{
      font-size: 20px;
     }
     #quote{
      font-size: 15px;
     }
}
  .slide4{
    background-color: #ffffff;
    text-align: center;
  }
  .slide4 h4{
   padding-top: 40px;
   font-size: 40px;
  }
  @media (max-width: 767px) {
   .slide4 h4 {
      font-size: 25px;
   }
}

  .slide2 p{
    margin: 20px 150px;
    font-size: 25px;
  }
  @media (max-width: 767px) {
   .slide2 p {
      margin: 0;
      font-size: 20px;
   }
}
#ot-box{
   padding: 60px 40px;
   display: flex;
}
#is-box{
   width: 33%;
   height: auto;
   position: relative;
   cursor: pointer;
}
#is-box span {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   color: rgb(0, 0, 0);
   font-size: 1.5em;
   text-align: center;
   opacity: 0;
   transition: opacity 0.1s ease-in-out;
 }
 #is-box:hover span {
   opacity: 1;
 }
 
 /* Hover effects */
 #is-box:hover .home-img {
   filter: blur(5px);
 }
 
 #is-box:hover a {
   opacity: 1;
 }
@media (max-width: 767px) {
   #is-box {
      width: 100%; 
      margin: 0;
   }
}
  .slide4 p{
    margin: 20px 200px;
    font-size: 25px;
  }
  @media (max-width: 767px) {
   .slide4 p {
      margin: 0;
      font-size: 20px;
   }
}
  .home-img{
   height: 90%;
   width: 90%;
  height: auto;
  transition: filter 0.3s ease-in-out;
  }
  @media (max-width: 767px) {
   .home-img {
      height: 60%;
   width: 90%;
   }
}
  .box {
    background-color: #54626D;
    color: #fff;
    padding: 35px 35px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    position:relative;
    bottom: 4%;
    font-size: 100%;
  }
  @media (max-width: 767px) {
   .box {
      display: flex;
      padding: 45px 100px;
      font-size: 60%;
      bottom: 5%;
   }
}
  .box:hover {
   background-color: #E7D49E;
   color: #000000;
 }


 #boz{
    width: 47%;
    height: auto;
    background-color: #ffffff;
    text-align: center;
    margin: 20px;
    color: #54626D;
 }

 @media (max-width: 767px) {
   #boz {
      width: 100%; /* Full width on mobile */
    margin: 10px 0; /* Space between columns */
   }
}
 #boz h2 {
   padding: 20px;
    font-size: 45px;
 }
 @media (max-width: 767px) {
   #boz h2 {
      font-weight: 400;
   }
}
 #boz p{
   font-size: 23px;
   margin: 10px;
 }
 .slide5{
    background-color: #ffffff;
 }
 .container{
    padding: 50px 50px
 }
.insta-box{
    background-color: #54626D;
    height: auto;
 }
 #insta-col1{
   width: 15%;
    padding: 20px 0;
 }
 #insta-col2{
   width: 85%;
    margin: 60px 0;
 }
 @media (max-width: 767px) {
   #insta-col1 {
      width: 50%;
    margin: 5px 0;
   }
   #insta-col2{
      width: 50%;
       margin: 50px 0;
    }
}
 .container h5 {
    margin-right: 60%;
 }
 .container p {
    margin-right: 60%;
 }
 #slide6{
    background-color: #54626D;
    color: #ffffff;
    font-size: 15px;
 }
 #ft-col1{
   width: 20%;
   text-align: left;
   padding: 50px 80px;
 }
 #ft-col2{
   width: 8%;
   text-align: left;
   padding: 50px 80px;
   margin-top: 14px;
 }
 #ft-col3{
   width: 15%;
   text-align: left;
   padding: 50px 80px;
 }
 #ft-col4{
   width: 15%;
   text-align: left;
   padding: 50px 80px;
 }
 #ft-col5{
   width: 15%;
   text-align: left;
   padding: 50px 80px;
 }
 #ft-col6{
   width: 22%;
   text-align: left;
   padding: 50px 80px;
   margin-top: 14px;
 }
 @media (max-width: 767px) {
   #ft-col1{
      width: 50%;
    }
    #ft-col2{
      width: 50%;
    }
    #ft-col3{
      width: 50%;
    }
    #ft-col4{
      width: 50%;
    }
    #ft-col5{
      width: 50%;
    }
    #ft-col6{
      width: 50%;
    }
}
 #p-ft{
   font-size: 11px;
   font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
   padding-left: 10%;
 }
 /* <----------------Legal---------------> */
 .legal{
   font-family: Georgia, 'Times New Roman', Times, serif;
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
 }
 .legal h1{
   color: #383535;
 }

 .le-bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;  /* Cover the entire background */
    z-index: -1;  /* Ensure the video is behind other content */
 }
  .legal h1{
    text-align: center;
    padding: 15%;
    font-size: 70px;
 }
 .le1{
    background-color: #ffffff;
    text-align: center;
    padding: 40px;
    font-size: 22px;
 }
 .le1 h3{
    font-size: 30px;
    padding: 10px;
 }
 @media (max-width: 767px) {
   .le1 {
      padding: 20px;
      font-size: 15px;
   }
   .legal h1 {
      padding: 10%;
      font-size: 40px;
   }
}
#le2{
    background-color: #ffffff;
    padding: 80px;
    font-size: 20px;
    height: auto;
}
@media (max-width: 767px) {
   #le2{
      padding: 20px;
      font-size: 15px;
  }
}
#vox{
   width: 50%;
   height: 310px;
}
#cox{
   width: 50%;
   height: auto;
}
.linkz {
   color: #000000;
 text-decoration: none;
 font-size: 150%;
}
.linkz:hover{
   color: #E7D49E;
}
#lopki {
   margin: 50px 30px;
   text-align: justify;
}
@media (max-width: 767px) {
   #lopki {
      margin: 80px 50px;
  }
}
@media (max-width: 767px) {
   #vox {
      height: auto;
      width: 90%;
      margin: 10px 10px;
      overflow-y: hidden;
  }
}
.inside-img {
    background-color: #54626D;
    padding: 20px;
    width: 150px;
    position: relative;
    display: inline-block;
}
.inside-img h4 {
    /* margin: 0 0 0 15px; */
    color: #fff;
}
.le3{
   color: #fff;
    text-align: center;
    margin: 100px 0 100px 0;
    font-size: 30px;
}
.mail{
   color: #fff;
   line-height: 2.2;
   text-decoration: none;
}
.boxs{
   color: #fff;
   line-height: 2;
   text-decoration: none;
}
#qwsa{
   line-height: 2.2;
   text-align: left;
   width: 150px;
}
#lgimg{
   height: 150%;
   width: 104%;
}
#ogimg{
   height: auto;
   width: 104%;
}
.linkk{
   text-decoration: none;
   color: #fff;
   font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
   font-size: 11px;
}
.linkk:hover{
  color: #E7D49E;
}
.linqq{
   text-decoration: none;
   color: #fff;
}
.linqq:hover{
   color: #E7D49E;
}
#Counsel1{
   background-color: #ffffff;
   padding: 10px 10px;
}
#Counsel2{
   text-align: center;
   font-size: 18px;
   line-height: 0.8cm;
   width: 110%;
   background: #8a6d526e;
   height: 550px;
   padding: 40px;
   border: #fff;
   border-style: solid;
   border-width: thin;
   border-radius: 2%;
}
#Counsel2:hover{
   background: #e7a223b6;
}
#mainpr{
   font-weight: 500;
   font-size: 22px;
   text-align: center;
   padding: 70px;
}
@media (max-width: 767px) {
   #mainpr {
      font-size: 18px;
      padding: 30px;
  }
}
#newxz{
   background-image: url('../assets/subpic.png');
   background-repeat: no-repeat;
   background-size: cover;
   display: flex;
   width: auto;
}
#newxz h5 {
   font-size: 130%;
}

/* ScrollUpButton.css */
.scroll-up-button {
   position: fixed;
   bottom: 50px;
   left: 95%;
   z-index: 1000;
 }
 
 .scroll-up-button button {
   background-color: #E7D49E;
   border: none;
   font-weight: bold;
   border-radius: 50%;
   color: #000000;
   cursor: pointer;
   font-size: 16px;
   padding: 6px;
   width: 35px; /* Adjust as needed */
  height: 35px; /* Adjust as needed */
   /* box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3); */
   transition: opacity 0.3s ease-in-out;
 }
 
 .scroll-up-button button:focus {
   outline: none;
 }
 
.swiper-button-next,
.swiper-button-prev {
  color: #E7D49E !important; /* Change this to your desired color */
}
.terms{
   background-color: #ffffff;
   text-align: center;
   padding: 80px 80px;
}
.privacy{
   background-color: #ffffff;
   text-align: center;
}

#rew {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin: 20px 0;
 }
 #box-left {
   width: 50%;
   background-color: #54626D; /* Light background color for left box */
   text-align: right; /* Align text to the right */
 }
 
 #box-left h2 {
   margin: 0;
   font-size: 4.3rem;
   color: #ffffff;
; /* Dark color for headings */
 }
 
 #box-right {
   width: 50%;
   background-color: #ffffff; /* White background color for right box */
   text-align: left; /* Align text to the left */
 }
 
 #box-right p {
   margin: 10px 0;
   font-size: 1.2rem;
   color: #34495e; /* Slightly darker color for paragraphs */
 }
 
 #box-right p:hover {
   color: #2980b9; /* Change color on hover */
 }
 
 @media (max-width: 768px) {
   .row {
     flex-direction: column;
     align-items: flex-start;
   }
 
   #box-left, #box-right {
     text-align: left; /* Left-align text on small screens */
   }
 }